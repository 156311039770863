import logo from './puh.jpg';
import './App.css';
import { useState, useEffect } from'react';

function App() {
  const [text, setText] = useState('');

  useEffect(() => {
    fetch('/api/verse/Genesis/1/1')
      .then((r) => r.json())
      .then((data) => {
        setText(data.text);
      })
      .catch((e) => console.log(e));
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Оранжевый пуховик" />
        <h2>Идут технические работы.</h2>
        {text && <p>{text}</p>}
      </header>
    </div>
  );
}

export default App;
